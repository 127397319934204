.App {
}

.page {
  position: "absolute";
  width: "100%";
  background-color: "#ececec";
  min-height: '100vh';
}
.page-enter {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 150px);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 0px);  
  transition: opacity 300ms, transform 300ms;
  /*transition-timing-function: ease-in;*/
}

.page-exit {
  opacity: 1;
  transform: scale(1);
  transform: translate(0px, 0px);
}

.page-exit-active {
  opacity: 0;
  /*transform: scale(0.9);*/
  /*transform: translate(0px, 100px);*/
  /*transition-timing-function: ease-out;  */
  transition: opacity 300ms, transform 300ms;
}

.sidebar-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;
}